import React from "react";
import StayInformed from "../../shared/components/StayInformed";
import CertificateCard from "./components/CertificateCard";
import { useIntl } from "react-intl";

const Certificates= () => {
  const intl = useIntl();
  const certificatesContent= [
    {
      id: 1,
      image: "/media/assets/certificates/intellectual.svg",
      description: "SmartViewCert",
      src:"SmartViewCertSrc",
      PdfUrl: "media/assets/certificates/4e Business Core.pdf",
      columns: 6,
    },
    {
      id: 2,
      image: "media/assets/certificates/city.svg",
      description: "CityServiceProvidersCert",
      src:"CityServiceProvidersCertSrc",
      PdfUrl: "media/assets/certificates/ISO-20000.pdf",
      columns: 6,
    },
    {
      id: 3,
      image: "media/assets/certificates/Logo_citc.svg",
      description: "InfoRegCert",
      src: "InfoRegCertSrc",
      PdfUrl: "media/assets/certificates/CITC Certificate (2023) E.pdf",
      columns: 6,
    },
    {
      id: 4,
      image: "media/assets/certificates/qualification.svg",
      description: "JadeerCert",
      src:"JadeerCertSrc",
      PdfUrl: "media/assets/certificates/Jadeer.pdf",
      columns: 6,
    },
    {
      id: 5,
      image: "media/assets/certificates/government.svg",
      description: "DigGovCert",
      src: "DigGovCertSrc",
      PdfUrl: "media/assets/certificates/شهادة-هيئة الحكومة الرقمية.pdf",
      columns: 6,
    },
    {
      id: 6,
      image: "media/assets/certificates/iso.svg",
      description: "ISOCert",
      src: "ISOCertSrc",
      PdfUrl: "media/assets/certificates/ISO-20000.pdf",
      columns: 6,
    },
  ];

  return (
    <div className="certificates-section">
      <div className="container">
        <div className="certificates-header text-center">
          <h2>{intl.formatMessage({ id: "OurCertifications" })}</h2>
          <p className="certificate-p m-auto">
            {intl.formatMessage({ id: "OurCertificationsDesc" })}
          </p>
      </div>

        <div className="row certificate-row">
          {certificatesContent.map((certificate) => (
            <CertificateCard certificate={certificate} key={certificate.id} />
          ))}
        </div>
      </div>
      <StayInformed />
    </div>
  );
};

export default Certificates;
