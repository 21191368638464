import React from "react";
import SliderComponent from "../../../shared/Slider";
import ImageBlurHash from "../../../shared/components/imageBlurHash";

const ClientsSlider = () => {
  const clientsImages = [
    "/media/assets/clients/Google.svg",
    "/media/assets/clients/Microsoft.svg",
    "/media/assets/clients/Zoom.svg",
    "/media/assets/clients/Tableau.svg",
    "/media/assets/clients/Oracle.svg",
    "/media/assets/clients/Qlik.svg",
  ];
  return (
    <div className="our-clients">
      <SliderComponent dots={false} slidesToShow={6}>
        {clientsImages.map((client, index) => (
          <React.Fragment key={index}>
            <div className="img-container">
              <ImageBlurHash
                hash="L8H{Zs?ZCR560T~U9E9~t94n#UD*"
                height={100}
                src={client}
                alt="client logo "
                classes="slider-image"
              />
              {/* <img src={client} alt="client logo " className="slider-image" /> */}
            </div>
          </React.Fragment>
        ))}
      </SliderComponent>
    </div>
  );
};

export default ClientsSlider;
