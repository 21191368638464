import React from "react";
import BlogDetails from "./components/BlogDetails";

const Blog = () => {
  return (
    <>
      <BlogDetails />
    </>
  );
};

export default Blog;
